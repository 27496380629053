import React, { MouseEvent } from 'react';

interface NoImageIconProps {
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const NoImageIcon = ({ className, onClick }: NoImageIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} width="15" height="16" viewBox="0 0 15 16" fill="none">
      <path d="M1.10748 0.607498L0.0424805 1.665L1.24998 2.8725V12.75C1.24998 13.575 1.92498 14.25 2.74998 14.25H12.6275L13.835 15.4575L14.8925 14.4L1.10748 0.607498ZM2.74998 12.75V4.3725L8.05248 9.675L7.43748 10.5L5.74998 8.25L3.49998 11.25H9.62748L11.1275 12.75H2.74998Z" fill="#47494B"/>
    </svg>
  );
};

export default NoImageIcon;