import HouseIcon from './house-icon';
import TownhouseIcon from './townhouse-icon';
import CondoIcon from './condo-icon';
import LandIcon from './land-icon';
import CommercialIcon from './commercial-icon';
import FarmIcon from './farm-icon';
import AttachedIcon from './attached-icon';
import DetachedIcon from './detached-icon';
import FireplaceIcon from './fireplace-icon';
import GarageIcon from './garage-icon';
import OpenHouseIcon from './open-house-icon';
import PoolIcon from './pool-icon';
import WaterfrontIcon from './waterfront-icon';
import SemiDetachedIcon from './semi-detached-icon';
import NoImageIcon from './no-image-icon';

export {
  HouseIcon,
  TownhouseIcon,
  CondoIcon,
  LandIcon,
  CommercialIcon,
  FarmIcon,
  AttachedIcon,
  DetachedIcon,
  FireplaceIcon,
  GarageIcon,
  OpenHouseIcon,
  PoolIcon,
  WaterfrontIcon,
  SemiDetachedIcon,
  NoImageIcon,
};
