import React, { MouseEvent } from 'react';

interface CommercialIconProps {
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const CommercialIcon = ({ className, onClick }: CommercialIconProps) => {
  return (
    <svg className={className} onClick={onClick} width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.66602 3.3335V4.25016" stroke="#191B1E" strokeWidth="1.2"/>
      <path d="M6.41602 3.3335V4.25016" stroke="#191B1E" strokeWidth="1.2"/>
      <path d="M3.66602 5.1665V6.08317" stroke="#191B1E" strokeWidth="1.2"/>
      <path d="M6.41602 5.1665V6.08317" stroke="#191B1E" strokeWidth="1.2"/>
      <path d="M3.66602 7V7.91667" stroke="#191B1E" strokeWidth="1.2"/>
      <path d="M6.41602 7V7.91667" stroke="#191B1E" strokeWidth="1.2"/>
      <path d="M3.66602 8.8335V9.75016" stroke="#191B1E" strokeWidth="1.2"/>
      <path d="M6.41602 8.8335V9.75016" stroke="#191B1E" strokeWidth="1.2"/>
      <path d="M1.83398 12.5V1.5H8.25065V12.5" stroke="#191B1E" strokeWidth="1.2"/>
      <path d="M0 12.5L10.0833 12.5" stroke="#191B1E" strokeWidth="1.2"/>
    </svg>
  );
};

export default CommercialIcon;
