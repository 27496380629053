import React, { DOMAttributes } from 'react';
import { buildClassName } from 'utils/build-class-name';
import TextInput from '@zoocasa/node-kit/components/controls/text-input';
import styles from './style.module.scss';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

import type { TextInputProps } from '@zoocasa/node-kit/components/controls/text-input';

type LabelOnClick = DOMAttributes<Element>['onClick'];
type Props = TextInputProps & {
  MiddleIcon: any;
  RightIcon: any;
  handleMiddleIconClick?: LabelOnClick;
  handleRightIconClick?: LabelOnClick;
  chosenArea?: string;
  onClick?: () => void;
  placeholder?: string;
  testId?: string;
  testIdMiddleIcon?: string;
  padded?: boolean;
};

export default function InputWithIcons({ MiddleIcon, RightIcon, handleRightIconClick, handleMiddleIconClick, className, chosenArea, onClick, placeholder = 'Location', padded, testId, testIdMiddleIcon, ...inputProps }: Props) {
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;
  const inputClassName = buildClassName(className, styles['icon-input'], MiddleIcon && styles['icon-input-middle'], RightIcon && styles['icon-input-right']);

  return (
    <div className={buildClassName(styles.component, padded && styles.padded)}>
      <TextInput id="locationInput" placeholder={placeholder} disabled={!!chosenArea} {...inputProps} className={inputClassName} onClick={onClick} data-testid={testId}/>
      {chosenArea && <p className={buildClassName(styles.tag, isExpTheme && styles['exp-tag'])}>{chosenArea} <MiddleIcon onClick={handleMiddleIconClick} /></p>}
      {
        MiddleIcon && (inputProps.value && !chosenArea) &&
        <label className={buildClassName(styles['icon-middle'], isExpTheme && styles['exp-icon-middle'])} onClick={handleMiddleIconClick} data-testid={testIdMiddleIcon}>
          <MiddleIcon />
        </label>
      }
      {
        RightIcon &&
        <label className={buildClassName(styles['icon-right'], isExpTheme && styles['exp-theme'])} onClick={handleRightIconClick}>
          <RightIcon />
        </label>
      }
    </div>
  );
}
