import React, { MouseEvent } from 'react';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
  testId: string;
}

const SearchIcon = ({ className, onClick, testId }: Props) => {
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;
  
  return (
    <div>
      { !isExpTheme ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="33" viewBox="0 0 35 33" fill="none" className={className} onClick={onClick} data-testid={testId}>
          <rect width="35" height="33" rx="2" fill="#191B1E"/>
          <path d="M16.1458 14.5833H15.3229L15.0312 14.3021C16.0521 13.1146 16.6667 11.5729 16.6667 9.89583C16.6667 6.15625 13.6354 3.125 9.89583 3.125C6.15625 3.125 3.125 6.15625 3.125 9.89583C3.125 13.6354 6.15625 16.6667 9.89583 16.6667C11.5729 16.6667 13.1146 16.0521 14.3021 15.0312L14.5833 15.3229V16.1458L19.7917 21.3438L21.3438 19.7917L16.1458 14.5833ZM9.89583 14.5833C7.30208 14.5833 5.20833 12.4896 5.20833 9.89583C5.20833 7.30208 7.30208 5.20833 9.89583 5.20833C12.4896 5.20833 14.5833 7.30208 14.5833 9.89583C14.5833 12.4896 12.4896 14.5833 9.89583 14.5833Z" fill="white" transform="translate(5, 4)"/>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.5" height="25.5s" viewBox="0 0 37 26" fill="none" className={className} onClick={onClick} data-testid={testId}>
          <g clipPath="url(#clip0_1244_9996)">
            <path d="M17.0573 15.1042H16.2261L15.9316 14.8201C16.9626 13.6207 17.5833 12.0636 17.5833 10.3698C17.5833 6.59281 14.5218 3.53125 10.7448 3.53125C6.96781 3.53125 3.90625 6.59281 3.90625 10.3698C3.90625 14.1468 6.96781 17.2083 10.7448 17.2083C12.4386 17.2083 13.9957 16.5876 15.1951 15.5566L15.4792 15.8511V16.6823L20.7396 21.9322L22.3072 20.3646L17.0573 15.1042ZM10.7448 15.1042C8.1251 15.1042 6.01042 12.9895 6.01042 10.3698C6.01042 7.7501 8.1251 5.63542 10.7448 5.63542C13.3645 5.63542 15.4792 7.7501 15.4792 10.3698C15.4792 12.9895 13.3645 15.1042 10.7448 15.1042Z" fill="#0C0F24"/>
          </g>
          <defs>
            <clipPath id="clip0_1244_9996">
              <rect width="22.25" height="22.25" fill="white" transform="translate(0.75 0.375)"/>
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default SearchIcon;
